import * as React from 'react'
import { useEffect, useState, useLayoutEffect } from 'react'
import Layout from '../../components/Layout/Layout'
import ReactECharts from 'echarts-for-react'
import telgeData from '../../assets/data/telge-extended-CO2.json'
import colorPalette from '../../utils/colorPalette'
// markup
import './dashboards.scss'

function sortByValue(a, b) {
  return a.value > b.value ? 1 : -1
}

const Telge = () => {
  const [co2Total, setCo2Total] = useState(0)
  const [co2TotalVikt, setCo2TotalVikt] = useState(0)
  const [co2Count, setCo2Count] = useState(0)
  const [varuCats, setVaruCats] = useState([])
  const [consCats, setConsCats] = useState([])

  useEffect(() => {
    let Co2Total = 0
    let Co2Count = 0
    let Co2TotalVikt = 0
    let varuomrade = {}
    let ccategory = {}
    for (let i = 0; i < telgeData.length; i++) {
      const element = telgeData[i]
      if (element['CO2eq']) {
        Co2Total += parseFloat(element['CO2eq'])
        Co2TotalVikt +=
          parseFloat(element['CO2eq']) * parseFloat(element['Kilo'])
        Co2Count += 1

        // Varuområden
        if (!varuomrade[element['Varuområde']]) {
          varuomrade[element['Varuområde']] = [element]
        } else {
          varuomrade[element['Varuområde']].push(element)
        }
        // Consupedia category
        const consucat = element['subcategory']?.category?.name
        if (consucat) {
          if (!ccategory[consucat]) {
            ccategory[consucat] = [element]
          } else {
            ccategory[consucat].push(element)
          }
        }
      }
    }

    const category_totals = Object.keys(ccategory).map((a) => {
      const cat = ccategory[a]
      let co_Co2Total = 0
      let co_Co2TotalVikt = 0
      let co_Co2Count = 0
      for (let i = 0; i < cat.length; i++) {
        const element = cat[i]
        if (element['CO2eq']) {
          co_Co2Total += parseFloat(element['CO2eq'])
          co_Co2TotalVikt +=
            parseFloat(element['CO2eq']) * parseFloat(element['Kilo'])
          co_Co2Count += 1
        }
      }
      return {
        name: a,
        co2: co_Co2Total,
        value: co_Co2TotalVikt,
        count: co_Co2Count
      }
    })
    const varuomraden_totals = Object.keys(varuomrade).map((a) => {
      const cat = varuomrade[a]
      let co_Co2TotalVikt = 0
      let co_Co2Count = 0
      for (let i = 0; i < cat.length; i++) {
        const element = cat[i]
        if (element['CO2eq']) {
          co_Co2TotalVikt +=
            parseFloat(element['CO2eq']) * parseFloat(element['Kilo'])
          co_Co2Count += 1
        }
      }
      return {
        name: a,
        value: co_Co2TotalVikt,
        count: co_Co2Count
      }
    })
    // console.log('cats', category_totals)

    setVaruCats(
      varuomraden_totals.sort(sortByValue).map((a) => ({
        ...a,
        // value: Math.round((a.value / Co2TotalVikt) * 10000) / 100,
        value: Math.round(a.value),
        tooltip: {
          formatter: '{b} : {c} kg ({d}%)',
          trigger: 'item'
        }
      }))
    )
    setConsCats(
      category_totals.sort(sortByValue).map((a) => ({
        ...a,
        // value: Math.round((a.value / Co2TotalVikt) * 10000) / 100,
        value: Math.round(a.value),
        tooltip: {
          formatter: '{b} : {c} kg ({d}%)',
          trigger: 'item'
        }
      }))
    )

    setCo2Count(Co2Count)
    setCo2Total(Co2Total)
    setCo2TotalVikt(Co2TotalVikt)
  }, [telgeData])

  const options_chart2 = {
    title: {
      text: '',
      subtext: '',
      left: 'center'
    },
    tooltip: {
      trigger: 'item'
    },
    // legend: {
    //   orient: 'vertical',
    //   left: 'left'
    // },
    series: [
      {
        name: 'Consupedia categories',
        type: 'pie',
        color: colorPalette(consCats.length),
        radius: '90%',
        data: consCats,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.1)'
          }
        }
      }
    ]
  }
  const options = {
    title: {
      text: '',
      subtext: '',
      left: 'center'
    },
    tooltip: {
      trigger: 'item'
    },
    // legend: {
    //   orient: 'vertical',
    //   left: 'left'
    // },
    series: [
      {
        name: 'Varumärkesområden',
        type: 'pie',
        radius: '90%',
        color: colorPalette(varuCats.length),
        data: varuCats,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.1)'
          }
        }
      }
    ]
  }

  // Category mean product CO2
  const mean_co2_options = {
    xAxis: {
      data: consCats.map((a) => a.name),
      axisLabel: {
        interval: 0,
        rotate: 30 //If the label names are too long you can manage this by rotating the label.
      }
    },
    tooltip: {
      trigger: 'item'
    },
    yAxis: {},
    series: [
      {
        color: colorPalette(consCats.length),
        type: 'bar',
        data: consCats.map((a) => Math.round((a.co2 / a.count) * 100) / 100)
      }
    ]
  }

  return (
    <Layout constricted>
      <title>Sustainability Dashboard - Consupedia</title>
      <div className="hero dark-bg alignfull">
        <div className="content-container">
          <div>
            <h1>Södertälje</h1>
            <h2>Augusti 2022</h2>
            <br />
            Totalt: <strong>{Math.round(co2TotalVikt / 10) / 100}</strong> Ton
            CO2ekv <br />
            Medelvärde produkt:{' '}
            <strong>{Math.round((co2Total / co2Count) * 100) / 100}</strong> kg
            CO2ekv / kg
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
      <section className="alignfull dashboard-content">
        <div className="section-container chart-container">
          <div className="cols" style={{ marginBottom: '3rem' }}>
            <div className="col pie-col">
              <h3>CO2e per kategori</h3>
              <br />
              <div className="single-chart-container">
                <ReactECharts
                  option={options_chart2}
                  notMerge={true}
                  lazyUpdate={true}
                  theme={'theme_name'}
                  style={{
                    height: '100%',
                    width: '100%'
                  }}
                  // onChartReady={}
                  // onEvents={}
                />
              </div>
            </div>
            <div className="col pie-col">
              <h3>CO2e per varuområde</h3>
              <br />
              <div className="single-chart-container">
                <ReactECharts
                  option={options}
                  notMerge={true}
                  lazyUpdate={true}
                  theme={'theme_name'}
                  style={{
                    height: '100%',
                    width: '100%'
                  }}
                  // onChartReady={}
                  // onEvents={}
                />
              </div>
            </div>
          </div>
          <div className="cols">
            <div className="col">
              <h3>Medel CO2 per kategori</h3>
              <br />
              <div className="single-chart-container">
                <ReactECharts
                  option={mean_co2_options}
                  notMerge={true}
                  lazyUpdate={true}
                  theme={'theme_name'}
                  style={{
                    height: '90%',
                    width: '100%'
                  }}
                  // onChartReady={}
                  // onEvents={}
                />
              </div>
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Telge
